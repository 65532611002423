import React from 'react';
import { graphql } from 'gatsby';
import { Icon } from '@iconify/react';
import arrowLeftOutlined from '@iconify/icons-ant-design/arrow-left-outlined';
import arrowRightOutlined from '@iconify/icons-ant-design/arrow-right-outlined';

import BlogTemplate from '../components/Template/BlogTemplate';
import DisqusCommentTemplate from '../components/Template/DisqusCommentTemplate';
import {
  WhatsappShareButton,
  WhatsappIcon,
  TwitterShareButton,
  TwitterIcon,
  TelegramShareButton,
  TelegramIcon,
  FacebookShareButton,
  FacebookIcon
} from 'react-share';
import BlogAnotherCard from '../components/Cards/BlogAnotherCard';

import ScrollTop from '../components/ScrollTop';
import Seo from '../components/Partials/Seo';

export default function BlogDetail({ data, pageContext }) {
  const post = data.markdownRemark;
  const siteUrl = data.site.siteMetadata.siteUrl;

  const { title, author, thumbnail, date } = post.frontmatter;
  const { html, timeToRead, excerpt, headings, fields } = post;

  const { prev, next } = pageContext;

  const pagePath = `blog${fields.slug}`;
  const pageUrl = `${siteUrl}/blog${fields.slug}`;

  return (
    <>
      <BlogTemplate tableOfContent={headings}>
        <Seo
          title={`${title} - Dicka Ismaji`}
          description={excerpt}
          image={thumbnail}
        />

        <div className="mb-8">
          <h1>{title}</h1>
          <p className="mb-0">
            <span className="text-red-400">{author}</span> at {date}
          </p>
          <small className="text-gray-300">{timeToRead} min read</small>
          <div className="flex justify-end mb-6">
            <WhatsappShareButton className="mx-1" url={pageUrl} title={title}>
              <WhatsappIcon size={28} round={50} />
            </WhatsappShareButton>
            <TwitterShareButton className="mx-1" url={pageUrl}>
              <TwitterIcon size={28} round={50} />
            </TwitterShareButton>
            <TelegramShareButton className="mx-1" url={pageUrl}>
              <TelegramIcon size={28} round={50} />
            </TelegramShareButton>
            <FacebookShareButton className="mx-1" url={pageUrl}>
              <FacebookIcon size={28} round={50} />
            </FacebookShareButton>
          </div>
          <div className="-mx-4">
            <img className="w-full lazyload" data-src={thumbnail} alt={title} />
          </div>
        </div>

        <div className="blog-content" dangerouslySetInnerHTML={{ __html: html }} />
        <div className="py-4">
          <hr />
        </div>

        <section>
          <h2>Another posts</h2>
          <div className="grid gap-4 grid-cols-2">
            <div className="text-left flex justify-start">
              {prev &&
                <div class="w-[90%]">
                  <span className="block mb-2">
                    <Icon icon={arrowLeftOutlined} className="inline" /> Previous
                  </span>
                  <BlogAnotherCard
                    title={prev.frontmatter.title}
                    timeToRead={prev.timeToRead}
                    slug={prev.fields.slug}
                    isFlex
                    thumbnail={prev.frontmatter.thumbnail} />
                </div>
              }
            </div>
            <div className="text-right flex justify-end">
              {next &&
                <div class="w-[90%]">
                  <span className="block mb-2">
                    Next <Icon icon={arrowRightOutlined} className="inline" />
                  </span>
                  <BlogAnotherCard
                    title={next.frontmatter.title}
                    timeToRead={next.timeToRead}
                    slug={next.fields.slug}
                    isFlex
                    justifyEnd
                    thumbnail={next.frontmatter.thumbnail} />
                </div>
              }
            </div>
          </div>
        </section>

        <div className="mt-8">
          <DisqusCommentTemplate
            url={pageUrl}
            identifier={pagePath}
            title={title}
          />
        </div>
      </BlogTemplate>
      <ScrollTop />
    </>
  );
}

export const query = graphql`
  query BlogDetailQuery($slug: String!) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      timeToRead
      excerpt
      fields {
        slug
      }
      frontmatter {
        title
        author
        date(formatString: "D MMM y H:mm")
        thumbnail
      }
      headings {
        value
        depth
      }
    }
  }
`;
