import { Link } from 'gatsby';
import React from 'react';

export default function BlogAnotherCard({
  title,
  slug,
  thumbnail,
  date,
  excerpt,
  timeToRead,
  justifyEnd,
  className
}) {
  return (
    <article className={className}>
      <Link to={`/blog${slug}`}>
        <div className={`md:flex flex-wrap ${justifyEnd ? 'justify-end' : ''}`}>
          <div className={`relative w-full mb-4 pt-[60%]`}>
            <img className="absolute object-cover max-h-full w-full h-full max-w-full select-none top-0 bottom-0 left-0 right-0 lazyload" data-src={thumbnail} alt={title} />
          </div>
          <div className='block w-100'>
            <small className="hidden md:block font-thin">{date}</small>
            <h3 className='py-0'>{title}</h3>
            <p className="mb-2 text-base">{excerpt}</p>
            <small className="font-thin hidden md:block">{timeToRead} min read</small>
          </div>
        </div>
      </Link>
    </article >
  );
}