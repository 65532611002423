import React from 'react'
import { Disqus } from 'gatsby-plugin-disqus'

export default function DisqusCommentTemplate({
  url,
  identifier,
  title
}) {
  return (
    <Disqus
      config={{
        url,
        identifier,
        title
      }}
    />
  )
}