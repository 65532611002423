import React, { useEffect, useState } from 'react';
import scrollTo from 'gatsby-plugin-smoothscroll';
import { Icon } from '@iconify/react';
import toTOp from '@iconify/icons-ant-design/to-top';

export default function ScrollTop() {
  const [isAppear, setIsAppear] = useState(false);
  const scrollBreakPoint = 500;

  const handleOnClick = () => {
    scrollTo('body');
  };

  useEffect(() => {
    window.onscroll = function () {
      if (window.scrollY > scrollBreakPoint) {
        setIsAppear(true);
      } else {
        setIsAppear(false);
      }
    };
  }, []);

  return (
    <div className={`fixed bottom-8 right-8 transition-opacity duration-[700] ${isAppear ? 'opacity-100' : 'opacity-0'}`}>
      <button className='bg-white text-black p-4 rounded-full' onClick={handleOnClick}>
        <Icon color='black' icon={toTOp} />
      </button>
    </div>
  );
}